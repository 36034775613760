.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &__icongroup {
        column-gap: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        max-width: 200px;
    }

    &__iconbutton {
        padding: 3px 8px;
        border: 0;
        border-radius: 50%;
    }
}
