.beefhome {
    padding: 0 2rem;
    margin-top: auto;
    margin-bottom: auto;


    &__title {
        display: flex;
        flex-direction: column;
        @include media-breakpoint-up(md) {
            flex-direction: row;
            column-gap: 1rem;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        max-width: 700px;
        margin: 0 auto 3rem auto;
        row-gap: 3rem;
        background-color: #ffffff90;
    }

    &__subtitle {
        display: block;
        font-size: 1rem;
        font-weight: $font-weight-normal;
        margin-right: auto;
        color: $color-secondary;
    }
}
